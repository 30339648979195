<template>
  <div class="design">
    <MainLayout>
      <template v-slot:content>
        <section>
          <h2>Design.</h2>

          <p>
            Wil je graag iets laten ontwerpen?<br /><br />
            We hebben ervaring met websites, logo's en huisstylen. <br />
            Maar ook voor offline design kun je bij ons terecht. Flyers,
            menukaarten of een spandoek? Wij regelen het van design tot de
            uiteindelijke druk. <br /><br />

            Benieuwd wat we al hebben gedaan? <br />
            Check ons portfolio met de tag
            <span class="baby-blue">design</span>.
          </p>

          <router-link class="btn" to="/portfolio"
            >Bekijk ons portfolio</router-link
          >
        </section>
      </template>
    </MainLayout>
  </div>
</template>

<script>
import MainLayout from "@/components/MainLayout.vue";

export default {
  name: "Design",
  components: {
    MainLayout,
  },
};
</script>